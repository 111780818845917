import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box } from "@chakra-ui/react";
import type { GenericHeaderProps } from "components/ui/GenericHeader";
import GenericHeader from "components/ui/GenericHeader";

export type GridTeaserListProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: GenericHeaderProps;
};

const GridTeaserList = (props: GridTeaserListProps) => {
    const phKey = `GridTeaserList-${props.params?.DynamicPlaceholderId}`;
    if (!props.fields) return null;
    return (
        <GenericHeader
            Id={props.rendering.uid ?? props.rendering.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <Box
                display={"grid"}
                gridTemplateColumns={{ base: "1fr", lg: "repeat(12 ,1fr)" }}
                columnGap="sp40"
                rowGap={{ base: "sp64", lg: "sp128" }}
            >
                <Placeholder name={phKey} rendering={props.rendering} />
            </Box>
        </GenericHeader>
    );
};
export default GridTeaserList;
