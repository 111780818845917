import type { ReactNode } from "react";
import { useEffect, useRef } from "react";
import { Flex } from "@chakra-ui/react";
import BundleIcon from "components/ui/BundleIcon";
import SplitType from "split-type";
import { MotionContainer } from "utils/framer/motionbox";
import type { Field, LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import SCLinkButton from "components/sc/SCLinkButton";
import SCHeading from "components/sc/SCHeading";
import SCText from "components/sc/SCText";
import { gtmCTAEvent } from "components/head/GTMTracking";
import { translateHeadingIn } from "themes/foundations/animations";
import getHeadingSize from "themes/utils/mappings/gh-headline-size";
import getSpacing from "themes/utils/mappings/gh-module-spacing";

export interface GenericHeaderProps {
    Id: string;
    AnchorId?: Field<string>;
    HeaderHeadline?: Field<string>;
    HeaderSubHeadline?: Field<string>;
    HeaderCTA?: LinkField;
    headlineSize?: string;
    spacingSize?: string;
    children: ReactNode;
}

export const modifyLineSplitting = (text: SplitType) => {
    text.lines?.forEach((line, index) => {
        line.innerHTML = `<span class="innerLine" style="transition-delay:${0.1 * (index + 1)}s;">${
            line.innerHTML
        }</span>`;
    });
};

const GenericHeader = (props: GenericHeaderProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const HeaderTitleRef = useRef<HTMLHeadingElement>(null);

    const headingSize = getHeadingSize(props.headlineSize);
    const spacingSize = getSpacing(props.spacingSize);

    const isNotEmpty = (value: string | undefined) => value !== undefined && value?.trim() !== "";

    const hasContent =
        pageEditing ||
        (!pageEditing && isNotEmpty(props.HeaderHeadline?.value)) ||
        isNotEmpty(props.HeaderSubHeadline?.value) ||
        isNotEmpty(props.HeaderCTA?.value?.href);

    const showHeading = pageEditing || (!pageEditing && isNotEmpty(props.HeaderHeadline?.value));

    const showTextAndCta =
        pageEditing ||
        (!pageEditing &&
            (isNotEmpty(props.HeaderSubHeadline?.value) ||
                isNotEmpty(props.HeaderCTA?.value?.href)));
    useEffect(() => {
        if (HeaderTitleRef.current) {
            HeaderTitleRef.current.style.opacity = "0";
        }
    }, []);
    return (
        <MotionContainer
            as="section"
            {...(props.AnchorId?.value && { id: props.AnchorId.value })}
            variant={"module"}
            position="relative"
            {...(!hasContent && {
                sx: {
                    ":not(:has(div)), :not(:has(div div))": {
                        py: 0,
                    },
                },
            })}
            aria-labelledby={`${props.Id}`}
            viewport={{ once: true, margin: "-200px" }}
            onViewportEnter={() => {
                if (!pageEditing && HeaderTitleRef.current) {
                    const text = new SplitType(HeaderTitleRef.current, {
                        types: "lines",
                        tagName: "span",
                    });
                    if (HeaderTitleRef.current) {
                        HeaderTitleRef.current.style.opacity = "1";
                    }
                    modifyLineSplitting(text);
                }
            }}
            py={spacingSize}
        >
            {hasContent && (
                <>
                    {showHeading && (
                        <SCHeading
                            ref={HeaderTitleRef}
                            textField={props.HeaderHeadline}
                            as="h2"
                            variant="moduleHeaderTitle"
                            size={headingSize}
                            id={`${props.Id}`}
                            left={0}
                            right={0}
                            overflow="hidden"
                            mb={["sp48", null, null, "sp96"]}
                            sx={{
                                wordWrap: "normal",

                                span: {
                                    wordWrap: "normal",
                                },
                                ".line": {
                                    height: "1em",
                                    overflow: "hidden",
                                },
                                ".innerLine": {
                                    transform: "translateY(-1.1em)",
                                    display: "block",
                                    animation: `${translateHeadingIn} 0.8s ease-out forwards`,
                                },
                            }}
                        />
                    )}

                    {showTextAndCta && (
                        <Flex
                            flexDir={["column", null, null, "row"]}
                            justifyContent="space-between"
                            alignItems={["center", null, null, "flex-end"]}
                            mb={["sp64", null, null, "sp96"]}
                        >
                            <SCText
                                textField={props.HeaderSubHeadline}
                                size="h3"
                                variant="moduleHeaderText"
                                textAlign={["center", null, null, "left"]}
                                w={["100%", null, null, "60%"]}
                                flex="0 0 auto"
                                {...(props.HeaderHeadline?.value !== "" && { id: props.Id })}
                            />
                            <SCLinkButton
                                linkField={props.HeaderCTA}
                                variant="outline"
                                mt={[
                                    props.HeaderSubHeadline?.value.trim() ? "sp40" : 0,
                                    null,
                                    null,
                                    0,
                                ]}
                                ml={{ lg: "auto" }}
                                rightIcon={<BundleIcon name="ArrowForward" />}
                                onClick={() => {
                                    gtmCTAEvent(
                                        props.HeaderCTA?.value?.title ??
                                            props.HeaderCTA?.value?.text ??
                                            "",
                                        "Generic header CTA",
                                        props.HeaderCTA?.value?.href
                                    );
                                }}
                            />
                        </Flex>
                    )}
                </>
            )}

            {props.children}
        </MotionContainer>
    );
};

export default GenericHeader;
